<template>
  <div class="container-tableUrl backPanelBlack">
    <div class="header-tableUrl">
      <p class="titleTableUrl textWhite">Resumen de últimas URL</p>
      <div class="container-reload">
        <span v-tooltip="{
          content: 'Actualizar', 
          class: 'tooltip-custom '
      }"  class="reload" @click="reload"></span>
      </div>
    </div>
    <div class="tableUrlShort">
      <datatable
        title=""
        :columns="tableColumns1"
        :clickable="false"
        :rows="tableRows1"
        :sortable="false"
        class="tableUrlShort"
      >
        <th class="th" slot="thead-tr">Tipo url</th>
        <th class="th" slot="thead-tr">Acciones</th>
        <template slot="tbody-tr" slot-scope="props">
          <td class="td">
            <div class="containerStatus">
              <p v-if="props.row.not_custom === true">Aleatoria</p>
              <p v-if="props.row.not_custom === false">Custom</p>
            </div>
          </td>
          <td class="td">
            <div class="container-router">
              <RouterLink
                to="/reportes/url-global"
                class="eye-content"
                @click.native="smsView(props.row)"
              >
                <span class="eye"></span>
              </RouterLink>
              <div class="download-content" @click="download(props.row)">
                <span class="download"></span>
              </div>
            </div>
          </td>
        </template>
      </datatable>
    </div>
    <Modal></Modal>
  </div>
</template>
<script>
import Modal from "../Common/Modal";
import DataTable from "vue-materialize-datatable";
import axios from "axios";

export default {
  components: {
    datatable: DataTable,
    Modal,
  },
  data() {
    return {
      tableColumns1: [
        {
          label: "Fecha",
          field: "created_at",
        },
        {
          label: "Enlace corto",
          field: "short_url",
        },
        {
          label: "Enlace original",
          field: "url",
        },
        {
          label: "Clics",
          field: "countClick",
        },
      ],
      tableRows1: [
        {
          created_at: "--- ",
          short_url: "---",
          url: "---",
          countClick: "---",
          not_custom: "---",
        },
      ],
    };
  },
  methods: {
    smsView(data) {
      this.$store.commit("saveOneCampaign", data);
    },
    download(data) {
      this.$store.commit("saveOneCampaign", data);
      this.$store.dispatch("getDataOneUrlList");
      setTimeout(() => {
        if (this.$store.state.oneCampaign.countClick == 0) {
          this.openModal();
        } else {
          let base_code = this.$store.state.detailUrlList.base_code;
          let domain = this.$store.state.detailUrlList.domain;
          let id = this.$store.state.oneCampaign._id;

          axios({
            url: `${process.env.VUE_APP_SERVICE_URL}services/url/download/${id}`,
            method: "GET",
            responseType: "blob",
            headers: {
              Authorization: `bearer ${this.$store.state.token} `,
              "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
            },
          })
            .then((response) => {
              let fileURL = window.URL.createObjectURL(
                new Blob([response.data])
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", `${domain}-${base_code}.csv`);
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }, 250);
    },
    reload() {
      this.$store.dispatch("getLatesData");
      this.$store.dispatch("chartUrlCustom");
      this.$store.dispatch("chartUrlAl");
    },
    openModal() {
      this.$modal.show("modalNoData");
    },
    filterData() {
      let data = this.$store.state.lastSMSCampaign;

      this.tableRows1 = data;
    },
  },
  created() {
    if (this.$store.state.lastSMSCampaign.length > 0) {
      this.filterData();
    }
  },
  watch: {
    "$store.state.lastSMSCampaign": function () {
      this.filterData();
    },
  },
};
</script>
<style scoped>
.indicatorTrue {
  content: "Aleatoria";
  color: black;
}
.indicatorFalse {
  content: "Custom";
  color: black;
}
.container-tableUrl {
  background: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  padding: 2rem 1rem;
  overflow-x: overlay;
}
.header-tableUrl {
  display: flex;
  margin-left: 2rem;
  justify-content: space-between;
}
.titleTableUrl {
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--color-17);
}

.titleTableUrl {
  font-family: var(--font-family-2);
  color: var(--color-17);
}

.parr {
  font-family: var(--font-family-2);
  color: var(--text-01);
  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
  padding: 1rem 0 0.5rem 1rem;
  text-align: start;
}
.div-bor {
  border-bottom: 2px solid var(--color-01);
}
.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border-radius: var(--borderRadius-1);
  cursor: pointer;
  display: flex;
  z-index: 1000;
}
.dd-button::after {
  z-index: 1000;
}

.dd-button:hover {
  background-color: var(--color-01);
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  border-radius: var(--borderRadius-1);
  margin: -33px 0 0 -129px;
  background-color: var(--color-06);
  width: 10rem;
  z-index: 1;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.icon {
  color: var(--color-01);
}
.icon2 {
  color: var(--color-15);
}
.active {
  z-index: 1000;
  color: var(--color-01);
}

.tableUrlShort {
  margin-top: 2rem;
}
.eye {
  content: url(../../assets/iconWhite/eye.svg);
}
.eye-content {
  background: var(--color-05);
  border-radius: var(--borderRadius-1);
  display: flex;
  width: 2.5rem;
  justify-content: space-evenly;
  align-items: center;
  height: 3vh;
}

.container-router {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.download {
  content: url(../../assets/iconLila/download.svg);
}
.download-content {
  background: #edeef4;
  border-radius: var(--borderRadius-1);
  display: flex;
  width: 2.5rem;
  justify-content: space-evenly;
  align-items: center;
  height: 3vh;
  cursor: pointer;
}
.reload {
  content: url(../../assets/iconOrange/reload.svg);
  width: 40px;
  height: 40px;
  transform: scale(0.9);
  cursor: pointer;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .container-tableUrl {
    padding: 2rem 0;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .container-tableUrl::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 10px;
    border: 2px solid #f1f2f3;
  }
  .container-tableUrl::-webkit-scrollbar:horizontal {
    height: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .titleTableUrl {
    font-size: 1.25em;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .container-tableUrl::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 10px;
    border: 2px solid #f1f2f3;
  }
  .container-tableUrl::-webkit-scrollbar:horizontal {
    height: 10px;
  }
}
@media (min-width: 1700px) and (max-width: 1999px) {
  .titleTableUrl {
    font-size: 1.25em;
  }
}
@media (min-width: 2000px) {
  .download-content,
  .download-content {
    width: 3rem;
  }
}
</style>

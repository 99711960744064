<template>
  <div @click="$router.go(-1)" class="currHead">
    <img class="imgArrow" src="../../assets/iconOrange/arrowLeft.svg" alt="" />
    <p class="title textWhite">Volver atrás</p>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.currHead {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 6vh;
}
.imgArrow {
  width: 1rem;
}
.title {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 1.563em;
  line-height: 32px;
  letter-spacing: 3px;
  color: var(--color-17);
  margin-left: 2rem;
}

@media (min-width: 768px) and (max-width: 1439px) {
  .title {
    font-size: 1.25em;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import HistoryUrlInd from "../views/HistoryUrlInd.vue";
import HistoyUrlGlo from "../views/HistoyUrlGlo.vue";
import ReportsUrlInd from "../views/ReportsUrlInd.vue";
import ReportsUrlGlo from "../views/ReportsUrlGlo.vue";
import store from "../store/index";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,


     meta: { isLogin: true },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/reportes/resumen-url-individual",
    name: "ReportesResumenIndividual",
    component: HistoryUrlInd,
    meta: { requiresAuth: true },
  },
  {
    path: "/reportes/resumen-url-global",
    name: "ReportesResumenGlobal",
    component: HistoyUrlGlo,
    meta: { requiresAuth: true },
  },
  {
    path: "/reportes/url-individual",
    name: "ReportesIdividual",
    component: ReportsUrlInd,
    meta: { requiresAuth: true },
  },
  {
    path: "/reportes/url-global",
    name: "Reportesglobal",
    component: ReportsUrlGlo,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth && store.state.token != undefined)) {
    
  const toke =sessionStorage.getItem('toke');
    if (toke != undefined) {
      next();
    } else {
      next({ path: "/" });
    }
    
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requiresAuth && store.state.token == undefined)) {
   const toke =sessionStorage.getItem('toke');
     if (toke == undefined) {
       next( {path: "/" });
     } else {
       next();
     }
     
   } else {
     next();
   }

   if(to.name=='Login' && sessionStorage.getItem('toke') != undefined){
   next({ path: "/home" });
   }
});

export default router;
<template>
  <div class="container-chartUrl backPanelBlack">
    <div class="tab-control">
      <div class="btns">
        <button
          @click="changeTab(1)"
          class="btn-tab url-ale"
          :class="{ active: currentTab == 1 }"
        >
          Url aleatoria
        </button>
        <button
          @click="changeTab(2)"
          class="btn-tab url-cust"
          :class="{ active: currentTab == 2 }"
        >
          Url custom
        </button>
      </div>
    </div>
    <div class="container-reload">
      <span  v-tooltip="{
          content: 'Actualizar', 
          class: 'tooltip-custom '
      }"  class="reload" @click="reload"></span>
    
    </div>
    <div class="headerChartUrl">
      <div>
        <p class="numberUrl textWhite">{{ totalClic }}</p>
        <p class="legendUrl textWhite">Total de clicks</p>
      </div>
      <div>
        <p class="numberUrl textWhite">{{ newLinks }}</p>
        <p class="legendUrl textWhite">Nuevos enlaces</p>
      </div>

      <div>
        <button class="btnChartUrl">Última semana</button>
      </div>
    </div>
    <div class="container-chartUrlShort">
      <div id="chart" v-if="currentTab == 1">
        <apexchart
          type="bar"
          height="250"
          :options="aleatoriachartOptions"
          :series="aleatoria"
          class="chartShortUrl"
        ></apexchart>
      </div>
      <div id="chart" v-if="currentTab == 2">
        <apexchart
          type="bar"
          height="250"
          :options="customchartOptions"
          :series="custom"
          class="chartShortUrl"
        ></apexchart>
      </div>
    </div>
    <Modal></Modal>
  </div>
</template>
<script>
import Modal from "./Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      dataChartCust: this.$store.state.dataChartUrlCust,
      dataChartAl: this.$store.state.dataChartUrlAl,
      userCustom: this.$store.state.user.custom_url,
      currentTab: 1,
      totalClic: this.$store.state.dataChartUrlAl.total.clicks,
      newLinks: this.$store.state.dataChartUrlAl.total.urls,
      aleatoria: [
        {
          name: "Clicks",
          data: [],
        },
        {
          name: "Enlaces",
          data: [],
        },
      ],
      custom: [
        {
          name: "Clicks",
          data: [],
        },
        {
          name: "Enlaces",
          data: [],
        },
      ],
      aleatoriachartOptions: {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        noData: {
          text: "No existen datos",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        colors: ["#503795", "#FF9F43"],
        yaxis: {
          title: {
            show: false,
          },

          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      customchartOptions: {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: "No existen datos",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#503795", "#FF9F43"],
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            show: false,
          },

          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == 1) {
        this.totalClic = this.dataChartAl.total.clicks;
        this.newLinks = this.dataChartAl.total.urls;
      }
      if (tab == 2) {
        (this.totalClic = this.dataChartCust.total.clicks),
          (this.newLinks = this.dataChartCust.total.urls);
      }
      if (this.userCustom == false && tab == 2) {
        this.$modal.show("modalInfo");
        this.totalClic = this.dataChartAl.total.clicks;
        this.newLinks = this.dataChartAl.total.urls;
        this.currentTab = 1;
      } else {
        return false;
      }
    },
    setDataChartCust() {
      let data = this.$store.state.dataChartUrlCust.info;
      let xaxis = data.map(function (element) {
        return element.date;
      });
      this.customchartOptions.xaxis = {
        categories: xaxis,
      };
      let clicks = data.map(function (element) {
        return element.clicks;
      });
      this.custom[0].data = clicks;
      let urls = data.map(function (element) {
        return element.urls;
      });
      this.custom[1].data = urls;
    },
    setDataChartAl() {
      let data = this.$store.state.dataChartUrlAl.info;
      let xaxis = data.map(function (element) {
        return element.date;
      });
      this.aleatoriachartOptions.xaxis = {
        categories: xaxis,
      };
      let clicks = data.map(function (element) {
        return element.clicks;
      });
      this.aleatoria[0].data = clicks;
      let urls = data.map(function (element) {
        return element.urls;
      });
      this.aleatoria[1].data = urls;
    },
    reload() {
      this.$store.dispatch("chartUrlCustom");
      this.$store.dispatch("chartUrlAl");
      this.$store.dispatch("getLatesData");
    },
  },
  created() {
    this.setDataChartCust();
    this.setDataChartAl();
  },
};
</script>
<style scoped>
.container-chartUrl {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
}
.headerChartUrl {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid var(--color-06);
  padding-bottom: 1rem;
}
.numberUrl {
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 0.694286px;
  color: var(--color-17);
}
.legendUrl,
.numberUrl {
  font-family: var(--font-family-3);
  text-align: start;
}
.legendUrl {
  font-weight: 500;
  font-size: 0.938em;
  color: var(--text-02);
}
.btnChartUrl {
  font-family: var(--font-family-3);
  font-weight: 500;
  font-size: 0.938em;
  text-align: center;
  color: var(--text-02);
  background: var(--color-18);
  border: 1px solid var(--color-18);
  box-sizing: border-box;
  border-radius: var(--borderRadius-1);
  padding: 0 0.5rem;
}
.tab-control {
  display: flex;
  justify-content: center;
  /*  margin-bottom: 2rem; */
}
.btn-tab {
  border-radius: var(--borderRadius-1);
  width: 9vw;
  padding: 0.3rem 0;
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  cursor: pointer;
  background: var(--color-18);
  border: 1px solid var(--color-18);
  color: var(--color-05);
}
.url-ale {
  margin-right: 2rem;
}
.url-cust {
  margin-left: 2rem;
}
.active {
  color: var(--color-18);
  background: var(--color-05);
  border: 1px solid var(--color-05);
}
.reload {
  content: url(../../assets/iconOrange/reload.svg);
  width: 40px;
  height: 40px;
  transform: scale(0.9);
  cursor: pointer;
}
.container-reload {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .btn-tab {
    width: 18vw;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .btn-tab {
    width: 13vw;
  }
  .numberUrl {
    font-size: 1.25em;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  .numberUrl {
    font-size: 1.25em;
  }
}
@media (min-width: 2000px) {
  .btn-tab {
    font-size: 1.25em;
  }
  .btnChartUrl {
    font-size: 1em;
  }
  .legendUrl {
    font-size: 1em;
  }
}
</style>

<template>
  <modal
    class="modal-logout"
    name="modalLogout"
    :width="540"
    :height="230"
    :adaptive="true"
    :clickToClose="true"
  >
    <div class="modalBody">
      <p class="titleModLog textWhite">Cerrar Sesión</p>
      <p class="subTitleModLog textWhite">¿Estás seguro?</p>
      <div class="btns">
        <button class="btn-confirm" @click="logout">Confirmar</button>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  methods: {
   
    logout() {
      
      sessionStorage.clear()
      this.$modal.hide("modalLogout");
      this.$router.push("/login");
      this.$store.commit("RESET_MODULE");
      
      
    },
  },
};
</script>
<style scoped>
.container-imgX {
  padding: 1.5rem 1.5rem 0 0;
  text-align: end;
}
.imgX {
  cursor: pointer;
}

p {
  font-family: var(--font-family-2);
  color: var(--text-01);
}
.titleModLog {
  font-weight: 600;
  font-size: 1.563em;
  padding-bottom: 1.5rem;
}
.subTitleModLog {
  font-size: 1.25em;
  letter-spacing: 0.694286px;
  padding-bottom: 2rem;
}
button {
  width: 6rem;
  border-radius: var(--borderRadius-1);
  border: none;
  cursor: pointer;
  font-family: var(--font-family-2);
  font-size: 1.063em;
  padding: 0.3rem 0 0.3rem 0;
}
.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.btn-confirm {
  color: white;
  background-color: var(--color-03);
}
</style>

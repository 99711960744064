<template>
  <div class="container-charts">
    <div class="title-camp">
      <p class="title textWhite">Campaña {{ titleCamp }}</p>
    </div>
    <div class="download-report">
      <button class="btn-down" @click="download()">
        <span class="img-down"></span>Descargar
      </button>
    </div>
    <div class="row row-chart">
      <div class="col-md-6">
        <div class="container-chart-one backPanelBlack">
          <p class="title textWhite">Dispositivos</p>
          <div id="chart">
            <apexchart
              type="donut"
              width="380"
              :options="devices.chartOptions"
              :series="devices.series"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-md-6 colTwo">
        <div class="container-chart-two backPanelBlack">
          <p class="title textWhite">Navegadores</p>
          <div id="chart">
            <apexchart
              type="donut"
              width="380"
              :options="browsers.chartOptions"
              :series="browsers.series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="container-summary backPanelBlack">
          <div class="container-urlSend">
            <div class="sendUrl">
              <span class="send"></span>
            </div>

            <div class="info">
              <p class="number">{{ msgSents }}</p>
              <p class="title-number textWhite">Mensajes enviados</p>
            </div>
          </div>
          <div class="openings">
            <div class="openUrl">
              <span class="open"></span>
            </div>

            <div class="info">
              <p class="number">{{ openings }}</p>
              <p class="title-number textWhite">Aperturas</p>
            </div>
          </div>
          <div class="lastOpening">
            <div class="last-op">
              <span class="last"></span>
            </div>

            <div class="info">
              <p class="number num-lastOp">{{ lastDate }}</p>
              <p class="title-number textWhite">Última apertura</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal></Modal>
  </div>
</template>

<script>
import Modal from "../Common/Modal";
import axios from "axios";
export default {
  props: ["trigger"],
  components: {
    Modal,
  },
  watch: {
    "$store.state.detailCampaignUrlList": function () {
      this.setData();
    },
  },
  data() {
    return {
      titleCamp: " ---",
      msgSents: "---",
      openings: "---",
      lastDate: "---",
      devices: {
        series: [],

        chartOptions: {
          noData: {
            text: "Loading...",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                  },
                  value: {
                    show: true,
                  },
                  total: {
                    show: true,
                    label: "Total",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    fontWeight: 100,
                  },
                },
              },
            },
          },
          labels: [],

          colors: [
            "#571845",
            "#900C3E",
            "#C70039",
            "#FF5733",
            "#FFC300",
            "#CCBBCE",
          ],
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 100,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 20,
            offsetY: 0,
            labels: {
              colors: undefined,
              useSeriesColors: false,
            },
            markers: {
              width: 8,
              height: 8,
              strokeWidth: 0,
              strokeColor: "#fff",
            },
            itemMargin: {
              horizontal: 2,
              vertical: 0,
            },
            onItemClick: {
              toggleDataSeries: true,
            },
            onItemHover: {
              highlightDataSeries: true,
            },
          },
          stroke: {
            colors: ["#fff"],
          },
        },
      },
      browsers: {
        series: [],

        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          noData: {
            text: "Loading...",
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                  },
                  value: {
                    show: true,
                  },
                  total: {
                    show: true,
                    label: "Total",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    fontWeight: 100,
                  },
                },
              },
            },
          },
          labels: [],
          colors: [
            "#9AC3DF",
            "#005A98",
            "#002D66",
            "#005D7C",
            "#00ACB6",
            "#CCBBCE",
            "#BD6B71",
            "#E7F3FA",
            "#B9B6BD",
          ],
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 100,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 20,
            offsetY: 0,
            labels: {
              colors: undefined,
              useSeriesColors: false,
            },
            markers: {
              width: 8,
              height: 8,
              strokeWidth: 0,
              strokeColor: "#fff",
            },
            itemMargin: {
              horizontal: 2,
              vertical: 0,
            },
            onItemClick: {
              toggleDataSeries: true,
            },
            onItemHover: {
              highlightDataSeries: true,
            },
          },
          stroke: {
            colors: ["#fff"],
          },
        },
      },
    };
  },
  mounted() {},
  methods: {
    setData() {
      let data = this.$store.state.detailCampaignUrlList;
      this.titleCamp = data.campaign_name;
      this.msgSents = data.sents;
      this.openings = data.clicks;
      this.lastDate = data.last_tracking;
      let dataOs = data.cants.os;
      let dataBrow = data.cants.browsers;

      let bro = dataBrow.map(function (element) {
        return element.browser;
      });
      let cant = dataBrow.map(function (element) {
        return element.cant;
      });
      this.browsers.chartOptions = {
        labels: bro,
      };
      this.browsers.series = cant;

      let os = dataOs.map(function (element) {
        return element.os;
      });
      let cantOs = dataOs.map(function (element) {
        return element.cant;
      });
      this.devices.chartOptions = {
        labels: os,
      };
      this.devices.series = cantOs;
    },
    download() {
      let send_id = this.$store.state.detailCampaignUrlList.send_id;
      let campaign_name = this.$store.state.detailCampaignUrlList.campaign_name;
      let id = this.$store.state.oneCampaignIn.id_unica;
      if (this.$store.state.oneCampaignIn.cants.clicks == 0) {
        this.openModal();
      } else {
        axios({
          url: `${process.env.VUE_APP_SERVICE_URL}services/campaign/download/${id} `,
          method: "GET",
          responseType: "blob",
          headers: {
            Authorization: `bearer ${this.$store.state.token} `,
            "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
          },
        })
          .then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              `${send_id}-${campaign_name}.csv`
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    openModal() {
      this.$modal.show("modalNoData");
    },
  },
};
</script>
<style scoped>
.download-report {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
.btn-down {
  display: flex;
  align-items: center;
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 0.938em;
  line-height: 19px;
  text-align: center;
  color: var(--color-01);
  background-color: var(--color-05);
  border: none;
  border-radius: var(--borderRadius-1);
  padding: 0.3rem;
  cursor: pointer;
}
.img-down {
  content: url(../../assets/iconWhite/downWhite.svg);
  margin-right: 0.5rem;
}
.container-chart-one,
.container-chart-two {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-bottom: 3rem;
  padding: 2rem 0;
}
.title {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 1.563em;
  line-height: 31px;
  color: var(--color-17);
}
.container-summary {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  display: flex;
  justify-content: space-around;
}
.colTwo {
  display: flex;
  justify-content: flex-end;
}
.send {
  content: url(../../assets/iconOrange/send.svg);
}
.number {
  font-family: var(--font-family-3);
  font-style: normal;
  font-weight: 500;
  font-size: 1.563em;
  line-height: 24px;
  /* or 80% */

  text-align: center;
  letter-spacing: 0.694286px;

  color: var(--color-03);
}
.title-number {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 24px;
  /* identical to box height, or 120% */

  text-align: center;
  letter-spacing: 0.694286px;

  color: var(--text-01);
}
.container-urlSend,
.openings,
.lastOpening {
  display: flex;
  align-items: center;
  height: 18vh;
}
.open {
  content: url(../../assets/iconOrange/open.svg);
}
.last {
  content: url(../../assets/iconOrange/bi_calendar-date.svg);
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 39%;
  align-items: flex-start;
}
.sendUrl,
.openUrl,
.last-op {
  margin-right: 1.5rem;
}
.num-lastOp {
  font-size: 1.5em;
}
@media (min-width: 768px) and (max-width: 967px) {
  .row-chart {
    justify-content: center;
  }
  .colTwo {
    justify-content: start;
  }
  .download-report {
    margin-right: 2rem;
    margin-top: 2rem;
  }
  .container-summary {
    flex-wrap: wrap;
    margin: 0 2rem;
  }
}
@media (min-width: 967px) and (max-width: 1023px) {
  .row-chart {
    justify-content: center;
  }
  .colTwo {
    justify-content: end;
  }
  .download-report {
    margin-right: 2rem;
    margin-top: 2rem;
  }
  .container-summary {
    flex-wrap: wrap;
    margin: 0 2rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .number {
    font-size: 1.25em;
  }
  .num-lastOp {
    font-size: 1.1em;
  }
  .container-summary {
    padding-top: 1.5rem;
  }
  .send,
  .open,
  .last {
    width: 60px;
    height: 60px;
    transform: scale(0.7);
  }
  .sendUrl,
  .openUrl,
  .last-op {
    margin-right: 0;
  }
}
</style>

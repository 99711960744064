<template>
  <div class="container backColorDark">
    <div class="div1">
      <Nav></Nav>
    </div>
    <div class="div2">
      <div class="row row-one">
        <div class="row rowTwo">
          <BackTo></BackTo>
        </div>
        <div class="row center-sm row-three">
          <div class="col-sm-12 col-md-11 col-lg-11">
            <Table v-if="this.$store.state.campaignList.length > 0"></Table>
            
            <div class="loading" v-else>
              <p class="textWhite">Cargando...</p>
              <img src="../assets/loading.gif" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-query">
      <img src="../assets/logo-altera.png" alt="" />
      <p class="text-s-query textWhite">
        Esta aplicación no está disponible para dispositivos móviles
      </p>
    </div>
   <ModalNoDataTable></ModalNoDataTable>
  </div>
</template>
<script>
import Nav from "../components/Nav/Nav";
import BackTo from "../components/Common/BackTo";
import Table from "../components/UrlInd/Table";
import ModalNoDataTable from "../components/Common/ModalNoDataTable";
export default {
  components: {
    Nav,
    BackTo,
    Table,
      ModalNoDataTable,
  },
  data() {
    return {};
  },
  methods: {
    valuePag() {
      let val = 1;
      this.$store.commit("saveValuePaginationG", val);
    },
      openModalNoData() {
      this.$modal.show("modalNoDataTable");
    },
  },
  created() {
    this.valuePag();
    this.$store.dispatch("getDataCampaignList");
  },
  
  beforeUpdate(){
if (this.$store.state.campaignList.length == 0) {
  this.openModalNoData()
}
  }
};
</script>

<style scoped>
.container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}
.div1 {
  width: 13%;
  height: auto;
}
.div2 {
  width: 87%;
}

.rowTwo,
.row-three {
  margin-top: 3rem;
}
.row-one {
  display: flex;
  height: 84%;
  flex-direction: column;
  flex-wrap: nowrap;

}

.s-query {
  display: none;
}
.loading {
  height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading p {
  font-family: var(--font-family-2);
  color: var(--text-01);
  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
}

img {
  width: 7%;
}
@media (max-width: 767px) {
  .s-query {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .div1,
  .div2 {
    display: none;
  }
  .text-s-query {
    text-align: center;
    font-family: var(--font-family-2);

    letter-spacing: 3px;
    font-size: 0.938em;
    font-weight: 600;
    margin-top: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .div1 {
    width: 20%;
  }

  .div2 {
    width: 80%;
  }
  .container-tableUrl {
    padding: 2rem 0.5rem;
    margin: 1rem;
  }
  .row-one {
    height: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .div1 {
    width: 15%;
  }

  .div2 {
    width: 85%;
  }
}
</style>

<template>
  <div class="container backColorDark">
    <div class="div1">
      <Nav></Nav>
    </div>
    <div class="div2  ">
      <div class="row  row-one ">
        <div class="row rowTwo">
          <BackTo></BackTo>
        </div>
        <div class="row center-sm row-three ">
          <div class="col-sm-12 col-md-11 col-lg-11 ">
            <Charts :trigger="trigger"></Charts>
          </div>
        </div>
      </div>
    </div>
    <div class="s-query">
      <img src="../assets/logo-altera.png" alt="" />
      <p class="text-s-query textWhite">
        Esta aplicación no está disponible para dispositivos móviles
      </p>
    </div>
  </div>
</template>
<script>
import Nav from "../components/Nav/Nav";
import BackTo from "../components/Common/BackTo";
import Charts from "../components/UrlInd/Charts";

export default {
  components: {
    Nav,
    BackTo,
    Charts,
  },
  data() {
    return {
      trigger: false,
    };
  },
  methods: {
    
  },
  mounted() {
    setTimeout(() => {
       this.$store.dispatch("getDataOneCampList");
    }, 250);
  },
    created() {

    
  },
};
</script>

<style scoped>
.container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}
.div1 {
  width: 13%;
  height: auto;
}
.div2 {
  width: 87%;
}



.row-one {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 2rem;
}

.s-query {
  display: none;
}

@media (max-width: 767px) {
  .s-query {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .div1,
  .div2 {
    display: none;
  }
  .text-s-query {
    text-align: center;
    font-family: var(--font-family-2);

    letter-spacing: 3px;
    font-size: 0.938em;
    font-weight: 600;
    margin-top: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .div1 {
    width: 20%;
  }
  .div2 {
    width: 80%;
  }
  .row-three {
    padding: 0;
    margin-top: 2rem;
  }
  .row-one {
    padding: 2rem 0;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .div1 {
    width: 15%;
  }

  .div2 {
    width: 85%;
  }
  .row-one {
    padding: 0;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./style.css"
import "./dark.css"
import VueApexCharts from 'vue-apexcharts'
import vmodal from 'vue-js-modal'
import VueClipboard from 'vue-clipboard2';
import store from './store'
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';

Vue.use(Tooltip);
Vue.use(VueClipboard);
Vue.use(vmodal)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
;
 new Vue({
  router,
  store,
  render: h => h(App),
 
}).$mount('#app')

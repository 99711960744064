<template>
  <div class="container-login">
    <div class="container-img">
      <img class="img-login" src="../assets/backLogin/back.1.png" alt="" />
      <img class='img-loginBack' src="../assets/backLogin/back.2.png" alt="">
    </div>
    <div class="loginForm backColorDark">
      <div class="div-switch">
        <div class="toggle-btn" id="_1st-toggle-btn">
          <input @click="toggleDarkMode"
          type="checkbox"
          id="checkbox"
          v-model="darkMode" />
          <span></span>
        </div>
      </div>
      <Form ></Form>
    </div>
    <div class="s-query">
      <img src="../assets/logo-altera.png" alt="">
     <p class="text-s-query textWhite">Esta aplicación no está disponible para dispositivos móviles </p> 
    </div>
  </div>
</template>
<script>
import Form from "../components/Login/Form";

export default {
  components: {
    Form,
  },
  data() {
    return {
       darkMode: false, 
    };
  },
  created() {
     let darkMode = localStorage.getItem("darkmode");
    if (darkMode == "1") {
      this.darkMode = true;
    } else {
      this.darkMode = false;
   
    } 
    
  },
   methods: {
       toggleDarkMode() {
      let app = document.getElementById("app");
      let darkMode = localStorage.getItem("darkmode");
      if (!darkMode || darkMode == "0") {
        localStorage.setItem("darkmode", "1");
        app.classList.add("dark");
        
      } else {
        localStorage.setItem("darkmode", "0");
        app.classList.remove("dark");
        
      }
    },

   }
};
</script>
<style scoped>
.container-login {
  display: flex;
  height: 100vh;
}
.container-img,
.loginForm {
  width: 50%;
}

.img-login,.img-loginBack {
  width: 100%;
  height: 100vh;
}
.loginForm {
  display: flex;
  justify-content: center;
  background-color: #f8f8ff;
  flex-direction: column;
}
/* switch */

.div-switch {

  position: absolute;
  top: 1em;
  right: 1em;
}

.toggle-btn {
  position: relative;
  width: 60px;
  height: 30px;
  margin: 0 auto;
  border-radius: 40px;
}

input[type="checkbox"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

/* First toggle btn */

#_1st-toggle-btn span {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 1;
  background-color: var(--color-01);
  box-shadow: 0px 2px 5px #d9d9d9;
  border-radius: 40px;
  transition: 0.2s ease background-color, 0.2s ease opacity;
}

#_1st-toggle-btn span:before,
#_1st-toggle-btn span:after {
  content: "";
  position: absolute;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.5s ease transform, 0.2s ease background-color;
}

#_1st-toggle-btn span:before {
  background-color: var(--color-01);
  transform: translate(-58px, 0px);
  z-index: 1;
}

#_1st-toggle-btn span:after {
  background-color: var(--color-03);
  transform: translate(8px, 0px);
  z-index: 0;
}

#_1st-toggle-btn input[type="checkbox"]:checked + span {
  background-color: var(--color-03);
}

#_1st-toggle-btn input[type="checkbox"]:active + span {
  opacity: 0.5;
}

#_1st-toggle-btn input[type="checkbox"]:checked + span:before {
  background-color: var(--color-03);
  transform: translate(28px, -6px);
}

#_1st-toggle-btn input[type="checkbox"]:checked + span:after {
  background-color: var(--color-01);
  transform: translate(33px, 0px);
}
.img-loginBack,.s-query{
  display:none
}

@media (max-width: 767px){
.s-query {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
  .container-img,.loginForm {
    display: none;
    
}
.text-s-query{
  text-align: center;
  font-family: var(--font-family-2);

  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
  margin-top: 1rem
}
}
</style>

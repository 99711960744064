<template>
  <div class="container-tableUrl backPanelBlack">
    <div class="header-tableUrl">
      <p class="titleTableUrl textWhite">Historial url individual</p>
      <span class="info textWhite"
        >*Información importada desde plataforma SMS</span
      >
    </div>
    <div class="tableRepIn">
      <datatable
        title=""
        :columns="tableColumns1"
        :clickable="false"
        :rows="tableRows1"
        :sortable="false"
        :paginate="false"
        locale="es"
        class="tableReportInd"
      >
        <th class="th" slot="thead-tr">Acciones</th>
        <template slot="tbody-tr" scope="props">
          <td class="td">
            <div class="container-router">
              <RouterLink
                to="/reportes/url-individual"
                class="eye-content"
                @click.native="smsView(props.row)"
              >
                <span class="eye"></span>
              </RouterLink>
              <div class="download-content" @click="download(props.row)">
                <span class="download"></span>
              </div>
            </div>
          </td>
        </template>
      </datatable>
      <div class="selector">
        <span class="textPag textWhite">Página</span>
        <input class="numberPag" v-model="value" readonly />
        <span
          id="change_pag_l"
          class="icon-left"
          @click="pagination_manualL()"
        ></span>
        <span
          id="change_pag_r"
          class="icon-right"
          @click="pagination_manualR()"
        ></span>
      </div>
    </div>
    <Modal></Modal>
    <ModalNoData></ModalNoData>
  </div>
</template>
<script>
import Modal from "../Common/Modal";
import DataTable from "vue-materialize-datatable";
import ModalNoData from "../Common/ModalNoData";
import axios from "axios";
export default {
  components: {
    datatable: DataTable,
    Modal,
    ModalNoData,
  },
  data() {
    return {
      dataInfo: "",
      rows: [],
      numberPag: this.$store.state.totalPageCampaignList,
      value: this.$store.state.valuePagination,
      tableColumns1: [
        {
          label: "Fecha",
          field: "created_at",
        },
        {
          label: "Campaña",
          field: "name",
        },
        {
          label: "Url enviadas",
          field: "cants.urls",
        },
        {
          label: "Aperturas",
          field: "cants.clicks",
        },
      ],
      tableRows1: [],
    };
  },
  methods: {
    smsView(data) {
      this.$store.commit("saveOneCampaignIn", data);
    },
    download(data) {
      this.$store.commit("saveOneCampaignIn", data);
      this.$store.dispatch("getDataOneCampList");
      setTimeout(() => {
        if (this.$store.state.oneCampaignIn.cants.clicks == 0) {
          this.openModal();
        } else {
          let send_id = this.$store.state.detailCampaignUrlList.send_id;
          let campaign_name =
            this.$store.state.detailCampaignUrlList.campaign_name;
          let id = this.$store.state.oneCampaignIn.id_unica;

          axios({
            url: `${process.env.VUE_APP_SERVICE_URL}services/campaign/download/${id}`,
            method: "GET",
            responseType: "blob",
            headers: {
              Authorization: `bearer ${this.$store.state.token} `,
              "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
            },
          })
            .then((response) => {
              let fileURL = window.URL.createObjectURL(
                new Blob([response.data])
              );
              let fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute(
                "download",
                `${send_id}-${campaign_name}.csv`
              );
              document.body.appendChild(fileLink);

              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }, 250);
    },
    openModal() {
      this.$modal.show("modalNoData");
    },
    openModalNoData() {
      this.$modal.show("modalNoDataTa");
    },
    filterData() {
      let data = this.$store.state.campaignList;
      this.tableRows1 = data;
    },
    filterDataPag() {
      let data = this.dataInfo;
      this.tableRows1 = data;
    },
    pagination_manualL() {
      if (this.value == 1) {
        return false;
      } else {
        this.value = this.value - 1;
        this.$store.commit("saveValuePagination", this.value);
        setTimeout(() => {
          this.getDataCampaignListPag();
        }, 100);
      }
    },
    pagination_manualR() {
      if (this.numberPag <= this.value) {
        this.openModalNoData();
      } else {
        this.value = this.value + 1;
        this.$store.commit("saveValuePagination", this.value);
        setTimeout(() => {
          this.getDataCampaignListPag();
        }, 100);
      }
    },

    getDataCampaignListPag() {
      let headers = {
        Authorization: `bearer ${this.$store.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };

      let data = {
        limit: 10,
        page: this.$store.state.valuePagination,
      };

      axios
        .post(
          `${process.env.VUE_APP_SERVICE_URL}services/campaign/list `,
          data,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.dataInfo = res.data.data.info;
          this.filterDataPag();
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          this.error = true;
        });
    },
  },
  created() {
    if (this.$store.state.valuePagination != 1) {
      this.getDataCampaignListPag();
    } else {
      this.filterData();
    }
  },
};
</script>
<style scoped>
.container-tableUrl {
  background: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  padding: 2rem;
  overflow-x: overlay;
  margin-bottom: 2rem;
}
.header-tableUrl {
  display: flex;
  margin-left: 2rem;
  flex-direction: column;
  align-items: flex-start;
}
.titleTableUrl {
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--color-17);
}

.titleTableUrl {
  font-family: var(--font-family-2);
  color: var(--color-17);
}

.parr {
  font-family: var(--font-family-2);
  color: var(--text-01);
  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
  padding: 1rem 0 0.5rem 1rem;
  text-align: start;
}

.tableRepIn {
  margin-top: 2rem;
}
.eye {
  content: url(../../assets/iconWhite/eye.svg);
}
.eye-content {
  background: var(--color-05);
  border-radius: var(--borderRadius-1);
  display: flex;
  width: 2.5rem;
  justify-content: space-evenly;
  align-items: center;
  height: 3vh;
}

.container-router {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.download {
  content: url(../../assets/iconLila/download.svg);
}
.download-content {
  background: var(--color-18);
  border-radius: var(--borderRadius-1);
  display: flex;
  width: 2.5rem;
  justify-content: space-evenly;
  align-items: center;
  height: 3vh;
  cursor: pointer;
}
.info {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 0.938em;
  line-height: 19px;

  text-align: center;

  color: var(--text-02);
}
.selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.numberPag {
  outline: none;
  width: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 10px;
  margin-left: 0.5rem;
  text-align: center;
  font-size: 12px;
}
.textPag {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.icon-left {
  content: url(../../assets/iconGrey/left.svg);
  cursor: pointer;
}
.icon-right {
  content: url(../../assets/iconGrey/right.svg);
  cursor: pointer;
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .titleTableUrl {
    font-size: 1.25em;
  }
}
</style>

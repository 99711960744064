<template>
  <div class="container backColorDark">
    <div class="div1">
      <Nav></Nav>
    </div>
    <div class="div2">
      <div class="row row-one">
        <div class="row rowTwo">
          <div class="col-sm-12 col-md-12 col-lg-5">
            <Welcome></Welcome>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <Charts :key="componentKey"></Charts>
          </div>
        </div>
        <div class="row center-sm row-three">
          <div class="col-sm-12 col-md-12 col-lg-11">
            <Table></Table>
          </div>
        </div>
      </div>
    </div>
    <div class="s-query">
      <img src="../assets/logo-altera.png" alt="" />
      <p class="text-s-query textWhite">
        Esta aplicación no está disponible para dispositivos móviles
      </p>
    </div>
  </div>
</template>
<script>
import Welcome from "../components/Home/Welcome";
import Charts from "../components/Home/Charts";
import Table from "../components/Home/Table";
import Nav from "../components/Nav/Nav";

export default {
  components: {
    Welcome,
    Charts,
    Table,
    Nav,
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  created() {
    this.valuePag();
  },
  methods: {
    valuePag() {
      let val = 1;
      this.$store.commit("saveValuePaginationG", val);
      this.$store.commit("saveValuePagination", val);
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
  watch: {
    "$store.state.dataChartUrlAl": function () {
      this.forceRerender();
    },
    "$store.state.dataChartUrlCust": function () {
      this.forceRerender();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}
.div1 {
  width: 13%;
  height: auto;
}
.div2 {
  width: 87%;
}
.row center-sm {
  height: 100%;
}
.rowTwo {
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
}
.row-one {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}
.row-three {
  margin: 2rem 0;
  width: 100%;
}
.s-query {
  display: none;
}
@media (max-width: 767px) {
  .s-query {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .div1,
  .div2 {
    display: none;
  }
  .text-s-query {
    text-align: center;
    font-family: var(--font-family-2);

    letter-spacing: 3px;
    font-size: 0.938em;
    font-weight: 600;
    margin-top: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .div1 {
    width: 20%;
  }
  .col-sm-12.col-md-12.col-lg-5 {
    flex-basis: 80%;
    margin-block-end: 2rem;
  }
  .col-sm-12.col-md-12.col-lg-6 {
    flex-basis: 80%;
  }
  .div2 {
    width: 80%;
  }
  .row-three {
    padding: 0 2rem;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .div1 {
    width: 15%;
  }
  .div2 {
    display: flex;
    justify-content: center;
    width: 85%;
  }

  .row-one {
    width: 90%;
  }
  .col-sm-12.col-md-12.col-lg-5 {
    display: flex;
    justify-content: center;
  }
  .col-sm-12.col-md-12.col-lg-6 {
    margin-top: 2rem;
  }
}
@media (min-width: 1600px) {
  .row-one {
    align-items: center;
  }
  .rowTwo {
    width: 90%;
  }
  .row.center-sm.row-three {
    width: 90%;
  }
}
@media (min-width: 1700px) {
  .rowTwo {
    width: 80%;
  }
}
@media (min-width: 2000px) {
  .row-one {
    justify-content: space-evenly;
  }
}
</style>

<template>
  <div class="container-nav backPanelBlack">
    <div class="logo-altera">
      <img class="img-logo" src="../../assets/logo-altera.png" alt="" />
      <div class="container-user">
        <avatar :username=nameUser  :size="80"  background-color="#9e9e9e"  color="#eeeeee"></avatar>
        <p class="name-user textWhite">{{ nameUser }}</p>
      </div>
    </div>

    <div class="menu-nav">
      <ul class="menu-list">
        <li class="menu-item">
          <routerLink to="/home" class="home menu-button">
            <p class="text">Dashboard</p>
          </routerLink>
        </li>
      </ul>
      <ul class="menu-list">
        <li class="menu-item">
          <routerLink
            to="/reportes"
            class="reports menu-button "
            v-slot="{ navigate ,isActive,isExactActive }"
            :disabled="true"
            custom
          >
          <button :active="isActive" @click="navigate" class="text" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"> <p class="text">Reportes</p> </button>
            
          </routerLink>

          <ul class="menu-sub-list backPanelBlack">
            <div class="sub-cont">
              <li class="url">
                <RouterLink
                  to="/reportes/resumen-url-individual"
                  class="menu-url"
                >
                  <p class="text-url">Url individual</p>
                </RouterLink>
              </li>
              <li class="url">
                <RouterLink to="/reportes/resumen-url-global" class="menu-url " >
                  <p class="text-url">Url global</p>
                </RouterLink>
              </li>
            </div>
          </ul> 
        </li>
      </ul>
    </div>
    <div class="footer-nav">
      <div class="div-switch">
        <div class="toggle-btn" id="_1st-toggle-btn">
          <input
            @click="toggleDarkMode"
            type="checkbox"
            id="checkbox"
            v-model="darkMode"
          />
          <span></span>
        </div>
      </div>

      <div class="container-logout" @click="openModal">
        <span class="icon-logout"></span>
        <p class="text-logout">Logout</p>
      </div>
    </div>
    <Modal></Modal>
  </div>
</template>
<script>
import Modal from "../Nav/Modal";
import Avatar from 'vue-avatar'
export default {
  components: {
    Modal,
    Avatar
  },
  
  data() {
    return {
      nameUser: this.$store.state.user.name,
      darkMode: false,
    };
  },
  methods: {
   
    openModal() {
      this.$modal.show("modalLogout");
    },
    toggleDarkMode() {
      let app = document.getElementById("app");
      let darkMode = localStorage.getItem("darkmode");
      if (!darkMode || darkMode == "0") {
        localStorage.setItem("darkmode", "1");
        app.classList.add("dark");
      } else {
        localStorage.setItem("darkmode", "0");
        app.classList.remove("dark");
      }
    },
  },
  created() {
    let darkMode = localStorage.getItem("darkmode");
    if (darkMode == "1") {
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
  },
};
</script>
<style scoped>
button.reports.menu-button {
  width:100%;
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-around;
  border: none;
  background-color: transparent;
}

.container-nav {
  width: 100%;
  height: 100%;
  background-color: var(--color-06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo-altera {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 16rem;
}
.img-logo {
  width: 65%;
  margin-bottom: 2rem;
}
.container-user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-user {
  width: 40%;
}
.name-user {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.313em;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: var(--color-03);
  text-align: center;
  margin-top: 0.5rem;
}
.home,
.reports {
  font-family: var(--font-family-3);
  font-style: normal;
  font-weight: 600;
  font-size: 0.938em;
  line-height: 25px;

  color: #8797a7;
}
.home::before {
  content: url(../../assets/iconGrey/dashboard.svg);
}
.home:hover::before {
  content: url(../../assets/iconWhite/dashboardWh.svg);
}

button.reports.menu-button::before {
  content: url(../../assets/iconGrey/reports.svg);
}

button.reports.menu-button:hover::before {
  content: url(../../assets/iconWhite/reportsWh.svg);
}

.menu-item:hover {
  background-color: var(--color-03);

  border-radius: 10px;
}
.menu-item:hover .text {
  color: var(--color-01);
}
.menu-item:hover .reports.menu-button::before {
  content: url(../../assets/iconWhite/reportsWh.svg);
}

.menu-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
a.home.menu-button,
a.reports.menu-button {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-around;
  padding-top: 0.2rem;
}
.menu-item {
  position: relative;

  height: 3rem;
  width: 95%;
}
.menu-sub-list {
  display: none;
  background-color: var(--color-06);
  position: absolute;
  left: 100%;
  right: 0;
  z-index: 100;
  width: 10rem;
  top: 0;
  flex-direction: column;
  height: 10rem;
}
.menu-sub-list:hover {
  display: flex;
}
.menu-button:hover + .menu-sub-list {
  display: flex;
}
.text {
  width: 6rem;
  text-align: start;
}

ul {
  list-style-type: none;
}
.url {
  display: flex;
  justify-content: center;
  height: 3rem;
  align-items: center;
}
.text-url {
  width: 6rem;
  text-align: start;
  font-family: var(--font-family-3);
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;
  color: #8797a7;
}
.sub-cont {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.url:hover {
  background-color: var(--color-03);
  border-radius: 10px;
}
.url:hover .text-url {
  color: var(--color-01);
}

/* switch */

.toggle-btn {
  position: relative;
  width: 60px;
  height: 30px;
  margin: 0 auto;
  border-radius: 40px;
}

input[type="checkbox"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

/* First toggle btn */

#_1st-toggle-btn span {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 1;
  background-color: var(--color-01);
  box-shadow: 0px 2px 5px #d9d9d9;
  border-radius: 40px;
  transition: 0.2s ease background-color, 0.2s ease opacity;
}

#_1st-toggle-btn span:before,
#_1st-toggle-btn span:after {
  content: "";
  position: absolute;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.5s ease transform, 0.2s ease background-color;
}

#_1st-toggle-btn span:before {
  background-color: var(--color-01);
  transform: translate(-58px, 0px);
  z-index: 1;
}

#_1st-toggle-btn span:after {
  background-color: var(--color-03);
  transform: translate(8px, 0px);
  z-index: 0;
}

#_1st-toggle-btn input[type="checkbox"]:checked + span {
  background-color: var(--color-03);
}

#_1st-toggle-btn input[type="checkbox"]:active + span {
  opacity: 0.5;
}

#_1st-toggle-btn input[type="checkbox"]:checked + span:before {
  background-color: var(--color-03);
  transform: translate(28px, -6px);
}

#_1st-toggle-btn input[type="checkbox"]:checked + span:after {
  background-color: var(--color-01);
  transform: translate(33px, 0px);
}
.footer-nav {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}
.icon-logout {
  content: url(../../assets/logout.svg);
}
.container-logout {
  background-color: var(--color-01);
  border-radius: 14px;
  width: 45%;
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  cursor: pointer;
}
.text-logout {
  font-family: var(--font-family-3);
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  color: #685bff;
}
.menu-url {
    width: 100%;
    display: flex;
    justify-content: center;
}
@media (min-width: 768px) and (max-width: 1024px){
.container-logout {
    width: 57%;
}

}
@media (min-width: 1024px) and (max-width: 1199px) {
  .container-logout {
    width: 56%;
  }
  .menu-nav {
    margin-bottom: 23rem;
  }
}
@media (min-width: 1600px){
.menu-sub-list {
   
    width: 10rem;
  
}
}
@media (min-width: 2000px) {
  .name-user {
   
    font-size: 1.63em;
  }
  .img-logo {
    width: 59%;
}
.home, .reports {

    font-size: 1.25em;
   
}
.menu-item {
    
    height: 4rem;
    
}
button.reports.menu-button {
    
    height: 4rem;
 
}
.menu-sub-list {
   
    width: 11rem;
  
}
.text-logout {

    font-size: 1em;

}
.text-url {
    width: 9rem;
    font-size: 1.25em;
}
.url {
  
    height: 3rem;
    
}
.menu-sub-list {
  
    height: 9rem;
 
}
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from '../router/index'


Vue.use(Vuex);
const initialState = {
  detailCampaignUrlList: {},
  detailUrlList: {},
  urlList: [],
  oneCampaign: {},
  oneCampaignIn: {},
  campaignList: [],
  lastSMSCampaign: {},
  token: undefined,
  user: {},
  valuePagination: 1,
  valuePaginationG: 1,
  dataChartUrlCust: {},
  dataChartUrlAl: {},
  totalPageUrlList: {},
  totalPageCampaignList: {}
};
export default new Vuex.Store({
  state: {
    detailCampaignUrlList: {},
    detailUrlList: {},
    urlList: {},
    oneCampaign: {},
    oneCampaignIn: {},
    campaignList: {},
    lastSMSCampaign: {},
    token: undefined,
    user: {},
    valuePagination: 1,
    valuePaginationG: 1,
    dataChartUrlCust: {},
    dataChartUrlAl: {},
    totalPageUrlList: {},
    totalPageCampaignList: {}
  },

  mutations: {
    saveTotalPageCampaignList(state, data) {
      state.totalPageCampaignList = data;
    },
    saveTotalPageUrlList(state, data) {
      state.totalPageUrlList = data;
    },
    saveDataChartUrlAl(state, data) {
      state.dataChartUrlAl = data;
    },
    saveDataChartUrlCust(state, data) {
      state.dataChartUrlCust = data;
    },
    saveValuePaginationG(state, data) {
      state.valuePaginationG = data;
    },
    saveValuePagination(state, data) {
      state.valuePagination = data;
    },
    saveDetialCampaignUrlList(state, data) {
      state.detailCampaignUrlList = data;
    },
    saveDetailUrlList(state, data) {
      state.detailUrlList = data;
    },
    saveOneCampaignIn(state, data) {
      state.oneCampaignIn = data;
    },
    saveOneCampaign(state, data) {
      state.oneCampaign = data;
    },

    saveUrlList(state, data) {
      state.urlList = data;
    },

    saveCampaignList(state, data) {
      state.campaignList = data;
    },

    saveLastSMS(state, data) {
      state.lastSMSCampaign = data;
    },
    saveToken(state, data) {
      state.token = data;
    },
    saveUser(state, data) {
      state.user = data;
    },
    RESET_MODULE(state) { // To reset the entire module
      Object.assign(state, initialState)
    },

  },
  actions: {
    async getLatesData(context) {
      let data = {};
      let headers = {
        Authorization: `bearer ${this.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      await axios
        .post(`${process.env.VUE_APP_SERVICE_URL}services/url/latest`, data, {
          headers: headers,
        })
        .then((response) => {
          /* console.log(response.data.data.info) */
          context.commit("saveLastSMS", response.data.data.info);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    async getDataUrlList(context) {
      let headers = {
        Authorization: `bearer ${this.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      let data = {
        limit: 10,
        page: 1,
      };
      await axios
        .post(`${process.env.VUE_APP_SERVICE_URL}services/url/list `, data, {
          headers: headers,
        })
        .then((res) => {
          context.commit("saveUrlList", res.data.data.info);
        /*  console.log('urltablaglob')  */
          /* console.log( res.data.data.info)  */
          context.commit("saveTotalPageUrlList", res.data.data.pages.total)
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          if (err.response.status == 401) {
            sessionStorage.clear()
            context.commit("RESET_MODULE");
            router.push("/login");

          } else {
            this.error = true;
          }

        });
    },

    async getDataOneCampList(context) {
      let id = this.state.oneCampaignIn.id_unica;

      let headers = {
        Authorization: `bearer ${this.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };

      let data = {};

      await axios

        .post(`${process.env.VUE_APP_SERVICE_URL}services/campaign/detail/${id} `, data, {
          headers,
        })
        .then((response) => {
          if (response) {
            /*  console.log('save')
             console.log( response.data.data) */
            context.commit("saveDetialCampaignUrlList", response.data.data);
            this.trigger = true;
          } else {
            console.log("none");
          }
        })
        .catch((err) => {

          if (err.response.status == 401) {
            sessionStorage.clear()
            context.commit("RESET_MODULE");
            router.push("/login");

          } else {
            console.log("AXIOS ERROR: ", err);
          }

        });
    },
    async getDataOneUrlList(context) {
      let id = this.state.oneCampaign._id;

      let headers = {
        Authorization: `bearer ${this.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };

      let data = {};

      await axios

        .post(`${process.env.VUE_APP_SERVICE_URL}services/url/detail/${id} `, data, {
          headers
        })
        .then((response) => {
          if (response) {
            /*  console.log("1")
             console.log(response.data.data) */

            context.commit("saveDetailUrlList", response.data.data);
            this.trigger = true;
          } else {
            console.log("none");
          }
        })
        .catch((err) => {

          if (err.response.status == 401) {
            sessionStorage.clear()
            context.commit("RESET_MODULE");
            router.push("/login");

          } else {
            console.log("AXIOS ERROR: ", err);
          }

        });
    },
    async getDataCampaignList(context) {

      let headers = {
        Authorization: `bearer ${this.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      let data = {
        limit: 10,
        page: 1,
      };
      await axios
        .post(`${process.env.VUE_APP_SERVICE_URL}services/campaign/list  `, data, {
          headers: headers,
        })
        .then((res) => {
          context.commit("saveCampaignList", res.data.data.info);
          /*  console.log('campaignlist')
            console.log( res.data.data.info)  */
          context.commit('saveTotalPageCampaignList', res.data.data.pages.total)

        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          if (err.response.status == 401) {
            sessionStorage.clear()
            context.commit("RESET_MODULE");
            router.push("/login");

          } else {
            this.error = true;
          }

        });
    },
    //chart url custom
    async chartUrlCustom(context) {
      let headers = {
        Authorization: `bearer ${this.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      let data = {

      };
      await axios
        .post(`${process.env.VUE_APP_SERVICE_URL}services/url/custom/weekly-info `, data, {
          headers: headers,
        })
        .then((res) => {
          /*    console.log('grf cus')
             console.log(res.data.data) */
          context.commit("saveDataChartUrlCust", res.data.data);

        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);

        });
    },
    //chart url aleatoria
    async chartUrlAl(context) {
      let headers = {
        Authorization: `bearer ${this.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      let data = {

      };
      await axios
        .post(`${process.env.VUE_APP_SERVICE_URL}services/url/random/weekly-info `, data, {
          headers: headers,
        })
        .then((res) => {
          context.commit("saveDataChartUrlAl", res.data.data);
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);

        });
    }
  },
  modules: {},
});
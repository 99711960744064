<template>
  <div class="container-url backPanelBlack">
    <div class="header-url">
      <p class="titleUrl textWhite">Bienvenido a URL corta</p>
    </div>

    <div class="body-url">
      <p class="titleBody textWhite">Personaliza tu URL</p>
      <div class="dropInp">
        <details class="custom-select">
          <summary class="radios">
            <input
              type="radio"
              name="item"
              id="default"
              title="URL"
              checked
              v-model="picked"
              value="url"
            />
            <input
              type="radio"
              name="item"
              id="item1"
              title="Aleatoria"
              v-model="picked"
              value="aleatoria"
            />
            <input
              type="radio"
              name="item"
              id="item2"
              title="Custom"
              v-model="picked"
              value="custom"
            />
          </summary>
          <ul class="list">
            <li>
              <label for="item1" class="option" @click="activeCustom"
                >Aleatoria</label
              >
            </li>
            <li>
              <label for="item2" class="option" @click="openModal"
                >Custom</label
              >
            </li>
          </ul>
        </details>
        <div class="inputAlias" v-if="active">
          <input
            type="text"
            name=""
            id=""
            placeholder="Custom code"
            class="alias"
            v-model="custom"
          />
        </div>
      </div>
      <div class="inpUrl">
        <input
          type="text"
          name=""
          id=""
          class="input-url"
          placeholder="Ingrese una url larga para acortarla"
          v-model="urlAleatoria"
        />
        <div class="error" v-if="error">
          <small class="textWhite">{{ errorInp }}</small>
        </div>
      </div>
      <div class="btnUrlShort">
        <button @click="sendInfo" :disabled="disabled">CREAR LA URL</button>
      </div>
    </div>
    <div v-if="urlCopy" class="url-copy">
      <input
        type="text"
        placeholder=""
        v-model="message"
        readonly
        class="url-short backPanelBlack textWhite"
      />

      <span
        type="button"
        v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        class="btn-copy"
      >
      </span>
    </div>
    <small v-if="copyOk" class="textWhite">{{ messageOk }} </small>
    <small v-if="errorData" class="textWhite">{{ errorDataMsg }} </small>
    <Modal></Modal>
  </div>
</template>
<script>
import Modal from "./Modal";
import axios from "axios";
const expReg = /\s/;

export default {
  components: {
    Modal,
  },
  data() {
    return {
      userCustom: this.$store.state.user.custom_url,
      active: false,
      Aleatoria: "Aleatoria",
      message: "",
      copyOk: null,
      messageOk: "",
      urlCopy: null,
      urlAleatoria: "",
      error: null,
      terminos: "",
      picked: "url",
      disabled: false,
      custom: "",
      errorData: false,
      errorInp: "",
      errorDataMsg: "",
    };
  },

  methods: {
    openModal() {
      const detail = document.querySelector("details");
      if (this.userCustom == false) {
        this.$modal.show("modalInfo");

        detail.removeAttribute("open");
      } else if (this.userCustom) {
        this.active = true;
        detail.removeAttribute("open");
      } else {
        this.active = false;
      }
    },

    activeCustom() {
      const detail = document.querySelector("details");
      if (this.active == true) {
        this.active = false;
        detail.removeAttribute("open");
      } else {
        this.active = false;
        detail.removeAttribute("open");
      }
    },
    onCopy() {
      this.copyOk = true;
      this.messageOk = "Se ha copiado con éxito ";
    },

    onError(e) {
      this.copyOk = true;
      this.messageOk = "Ha ocurrido un error, intenta nuevamente";
      console.log(e);
    },
    sendInfo() {
      if (this.urlAleatoria == "" && this.picked == "url") {
        this.error = true;
        this.errorInp = "* Debe seleccionar una opción e ingresar una Url";
      }
      if (this.urlAleatoria !== "" && this.picked == "url") {
        this.error = true;
        this.errorInp = "* Debe seleccionar una opción ";
      }
      if (this.urlAleatoria == "" && this.picked == "aleatoria") {
        this.error = true;
        this.errorInp = "* Debe ingresar una Url";
        this.urlCopy = null;
        this.messageOk = " ";
      }
      if (this.urlAleatoria !== "" && this.picked == "aleatoria") {
        this.error = null;
        this.urlCopy = true;
        this.sendUrl();
      }
      if (this.picked == "custom" && this.custom == "") {
        this.error = true;
        this.errorInp = "* Debe ingresar un custom code e ingresar una Url";
        this.urlCopy = null;
        this.messageOk = " ";
      }
      if (this.urlAleatoria == "" && this.custom !== "") {
        this.error = true;
        this.errorInp = "* Debe ingresar una Url";
        this.urlCopy = null;
        this.messageOk = " ";
      }
      if (this.urlAleatoria !== "" && this.custom !== "") {
        if (expReg.test(this.custom)) {
          this.error = true;
          this.errorInp = "*Custom code no debe incluir espacios en blanco";
        } else {
          this.error = null;
          this.urlCopy = true;
          this.sendUrl();
        }
      }
      if (this.userCustom == false && this.picked == "custom") {
        this.openModal();
        this.error = null;
      }
    },
    sendUrl() {
      let headers = {
        Authorization: `bearer ${this.$store.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      let data = {
        url: this.urlAleatoria,
        custom_code: this.custom,
      };
      axios

        .post(`${process.env.VUE_APP_SERVICE_URL}services/url/create`, data, {
          headers: headers,
        })
        .then((res) => {
          this.message = res.data.data.short_url;
          this.urlAleatoria = "";
          this.custom = "";
          this.errorData = false;
          this.getSMSLatesData();
          this.$store.dispatch("chartUrlAl");
          this.$store.dispatch("chartUrlCustom");
          
        })
        .catch((err) => {
          /* console.log(err.response); */
          if (err.response.data.error_message == "Url is not valid") {
            this.errorData = true;
            this.errorDataMsg = " *Debe ingresar una Url valida";
            /* console.clear(); */
          } else if (err.response.data.error_message == "Url already exists") {
            this.errorData = true;
            this.errorDataMsg = " *Custom code ya está siendo utilizado";
          } else {
            if (err.response.status == 401) {
              sessionStorage.clear()
              this.$store.commit("RESET_MODULE");
              this.$router.push("/login");
            } else {
              this.errorData = true;
              this.errorDataMsg = " *Se produjo un error, intente nuevamente";
              /* console.clear(); */
            }
          }
        });
    },
    getSMSLatesData() {
      this.$store.dispatch("getLatesData");
      this.$store.dispatch("getDataUrlList");
    },
  },
};
</script>
<style scoped>
.container-url {
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  width: 90%;
  padding: 1rem 2rem 0;
  height: 100%;
}
.titleUrl {
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}
.titleBody,
.titleUrl {
  font-family: var(--font-family-2);
  color: var(--color-17);
  font-size: 1.563em;
  text-align: start;
}
.titleBody {
  font-size: 1.25em;
}
.titleBody {
  letter-spacing: 3px;
  font-weight: 600;
}
.labelDrop {
  width: 50%;
}
.input-url {
  width: 100%;
  padding: 0.8rem;
}
.alias,
.input-url {
  border: 1px solid var(--color-05);
  background: var(--color-01);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.11);
  border-radius: var(--borderRadius-1);
  outline: none;
  padding-left: 2rem;
}

.url-short {
  border: none;
  outline: none;
  border-bottom: 2px solid var(--color-05);
  width: 100%;
  padding-left: 0.5rem;
}
.btn-copy {
  width: 20%;
  border-radius: 0 var(--borderRadius-1) var(--borderRadius-1) 0;
  border: none;
  content: url(../../assets/iconLila/copyLink.svg);
  width: 50px;
  height: 50px;
  transform: scale(0.5);
  cursor: pointer;
}
small,
.btn-copy {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}
.alias {
  width: 100%;
  height: 100%;
}
.input-url::placeholder,
.alias::placeholder {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  letter-spacing: 0.694286px;
  color: var(--color-11);
}

label.arrowD1::before {
  content: url(../../assets/iconLila/ArrowDown.svg);
}
input:checked ~ div label::before {
  content: url(../../assets/iconLila/ArrowUp.svg);
}

.alias,
.input-url,
#default,
#item1,
#item2,
ul,
input[type="radio"]:after,
.url-short {
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.694286px;
  color: var(--text-02);
}

.dropInp {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.inputAlias {
  width: 48%;
}
.btnUrlShort button {
  width: 100%;
  background: var(--color-05);
  border-radius: var(--borderRadius-1);
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 1.063em;
  text-align: center;
  letter-spacing: 0.694286px;
  color: var(--color-01);
  height: 6vh;
  border: none;
  cursor: pointer;
  margin-top: 2rem;
}

details {
  position: relative;
  width: 50%;
  margin-right: 1rem;
}

summary {
  padding: 0.8rem;
  cursor: pointer;
  border-radius: var(--borderRadius-1);
  background-color: var(--color-01);
  list-style: none;
  border: 1px solid var(--color-05);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

details[open] summary:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

summary:after {
  content: "";
  display: inline-block;
  /* float: right; */
  width: 0.5rem;
  height: 0.5rem;
  border-bottom: 2px solid var(--color-05);
  border-left: 2px solid var(--color-05);
  border-bottom-left-radius: 2px;
  transform: rotate(315deg) translate(0%, 0%);
  transform-origin: center center;
  transition: transform ease-in-out 50ms;
}

summary:focus {
  outline: none;
}

details[open] summary:after {
  transform: rotate(-225deg) translate(0%, 0%);
}

ul {
  width: 100%;
  background: var(--color-01);
  position: absolute;
  /* top: calc(100% + 0.5rem); */
  left: 0;
  padding: 0.5rem 0;
  margin: 0;
  box-sizing: border-box;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}

li {
  margin: 0;
  height: 6vh;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: flex-start;
}
li:hover {
  background-color: #9277ee;
  color: var(--color-01);
  border-radius: 10px;
}

li:first-child {
  padding-top: 0;
}

li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
summary.radios {
  counter-reset: radios;
}

input[type="radio"] {
  counter-increment: radios;
  appearance: none;
  display: none;
}

input[type="radio"]:checked {
  display: inline;
}

input[type="radio"]:after {
  content: attr(title);
  display: inline;
}

ul.list {
  counter-reset: labels;
}

label {
  width: 100%;
  display: block;
  cursor: pointer;
  text-align: start;
  padding-left: 1rem;
}
.body-url {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 3rem;
}
.url-copy {
  margin-top: 1rem;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .container-url {
    width: 100%;
    padding: 1rem 2rem;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .titleUrl,
  .titleBody {
    font-size: 1.3em;
  }
  .container-url {
    width: 68%;
    padding-bottom: 1rem;
  }
  .url-copy {
    margin-top: 1.5rem;
  }

  .dropInp {
    justify-content: flex-start;
  }
  .btnUrlShort button {
    font-size: 1em;
  }
  .btnUrlShort {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1200px) and (max-width: 1345px) {
  .titleUrl,
  .titleBody {
    font-size: 1.3em;
  }
  .btnUrlShort button {
    font-size: 1em;
  }
}
@media (min-width: 1700px) and (max-width: 1999px) {
  .btnUrlShort button {
    height: 5.5vh;
  }
  .titleUrl {
    font-size: 1.25em;
  }
  .titleBody {
    font-size: 1.05em;
  }
}
@media (min-width: 2000px) {
  summary {
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .url-copy {
    margin-top: 2.3rem;
  }
  input#default,
  input#item1,
  input#item2,
  .option,
  .input-url,
  .alias,
  .btnUrlShort button,
  .url-short {
    font-size: 1.25em;
  }
  .titleUrl {
    font-size: 1.63em;
  }
  .titleBody {
    font-size: 1.35em;
  }
  .btnUrlShort button {
    height: 4.5vh;
  }
  li {
    height: 4vh;
  }
}
</style>

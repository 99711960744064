<template>
  <modal
    class="modal-logout"
    name="modalNoData"
    :width="550"
    :height="150"
    :adaptive="true"
    :clickToClose="false"
  >
    <div class="modalBody">
      <p class="titleModLog textWhite">No existen registros para descargar</p>
      <div class="btns">
        <button class="btn-cancel" @click="$modal.hide('modalNoData')">OK</button>
      </div>
    </div>
  </modal>
</template>
<script>
export default {};
</script>
<style scoped>
.container-imgX {
  padding: 1.5rem 1.5rem 0 0;
  text-align: end;
}
.imgX {
  cursor: pointer;
}

p {
  font-family: var(--font-family-2);
  color: var(--text-01);
}
.titleModLog {
  font-weight: 600;
  font-size: 1.25em;
  padding-bottom: 1.5rem;
}

button {
  width: 6rem;
  border-radius: var(--borderRadius-1);
  border: none;
  cursor: pointer;
  font-family: var(--font-family-2);
  font-size: 1.063em;
  padding: 0.3rem 0 0.3rem 0;
}
.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.btn-cancel{
    margin-right: 1rem;
    color:var(--color-03);
    background-color: var(--color-18)
}

</style>

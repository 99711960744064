<template>

  <div id="app"  :class="{dark}">
    <router-view/>
  </div>

</template>
<script>
export default {
  
  created () {
    // Leer la información de estado en sessionStorage cuando se carga la página
    if (sessionStorage.getItem("store") ) {
        this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    } 
 
         // Guarde la información en vuex en sessionStorage cuando se actualice la página
    window.addEventListener("beforeunload",()=>{
        sessionStorage.setItem("store",JSON.stringify(this.$store.state))
         
    });
  
 

  
},

    computed: {
      dark() {
        let darkMode = localStorage.getItem('darkmode')
        if(!darkMode || darkMode == '0') {
          return false
        } 

        return true
      }
    },
     
};
</script>
<style>

</style>

<template>
  <div class="cont-login backColorDark">
    <div class="header-login">
      <img class="imgHeader" src="../../assets/logo-altera.png" alt="" />
    </div>
    <div class="inputs">
      <input
        class="input-email backColorDark textWhite"
        type="email"
        name=""
        id="i-email"
        placeholder="Ingresa tu mail"
        v-model="user"
      />
      <input
        class="input-pass backColorDark textWhite"
        type="password"
        name=""
        id="i-pass"
        placeholder="Ingresa tu contraseña"
        v-model="pass"
      />
    </div>
    <div class="errorInfo" v-if="errorInfo">
      <small class="textWhite">{{ errorInfo }}</small>
    </div>

    <div class="btn">
      <routerLink to="#">
        <button @click.prevent="login">Iniciar Sesión</button>
      </routerLink>
    </div>

    <div>
      <div class="error" v-if="error">
        <small>* Datos de acceso incorrectos</small>
      </div>
    </div>
    <p class="parrYear textWhite">{{ new Date().getFullYear() }} Altera</p>
  </div>
</template>
<script>
import axios from "axios";


const regMail =
  /^([a-zA-ZñÑ0-9_.+-])+@(([a-zA-ZñÑ0-9-])+\.)+([a-zA-ZñÑ0-9]{2,4})+$/;
let time = new Date();
time.setTime(time.getTime() + 3 * 60 * 60 * 1000); // 3 horas

export default {
  data() {
    return {
      user: null,
      pass: null,
      errorInfo: false,
      error: false,
    };
  },
  methods: {
    login() {
      if (this.user == null && this.pass == null) {
        this.errorInfo = true;
        this.errorInfo = "Debe ingresar todos sus datos";
      } else if (regMail.test(this.user) === false) {
        this.errorInfo = true;
        this.errorInfo = "Debe ingresar un usuario valido";
      } else {
        this.getToken();
      }
    },

    getToken() {
      let headers = {
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      let data = {
        email: this.user,
        password: this.pass,
      };
      axios
        .post(`${process.env.VUE_APP_SERVICE_URL}auth/login`, data, {
          headers: headers,
        })
        .then((res) => {
     
          this.$store.commit("saveToken", res.data.token);
          sessionStorage.setItem('toke',res.data.token)
          setTimeout(() => {
            this.load = true;
            this.$router.push("/home");
          }, 1000);
          this.getSMSLatesData();
          this.getUser();
          this.$store.dispatch("chartUrlCustom");
          this.$store.dispatch("chartUrlAl");
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          this.error = true;
        });
    },
    getUser() {
      let headers = {
        Authorization: `bearer ${this.$store.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };

      axios
        .get(`${process.env.VUE_APP_SERVICE_URL}auth/me`, {
          headers: headers,
        })
        .then((res) => {
          this.$store.commit("saveUser", res.data.user);
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    },
    getSMSLatesData() {
      this.$store.dispatch("getLatesData");
    },
  },
};
</script>
<style scoped>
.cont-login {
  background-color: #f8f8ff;
  border-radius: var(--borderRadius-1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inputs {
  display: flex;
  flex-direction: column;
  width: 37%;
  margin-top: 7rem;
  margin-bottom: 1.5rem;
}
.footLogin {
  display: flex;
  margin-top: 4rem;
}
.input-email,
.input-pass {
  background-color: var(--color-01);
  border-radius: var(--borderRadius-1);
  border: none;
  padding: 1rem 0;
  outline: none;
  padding-left: 1rem;
  box-shadow: 0px 2px 15px #d9d9d9;
}
::placeholder {
  line-height: 16px;
  font-size: 0.938em;
  color: var(--color-17);
  padding-left: 1rem;
}
::placeholder,
.parr,
p,
.error,
.errorInfo {
  font-family: var(--font-family-2);
}

.parr {
  font-weight: 600;
  line-height: 16px;
  font-size: 0.688em;
  color: var(--color-17);
  padding: 0 0.3rem;
  cursor: pointer;
}
.d-parr {
  border-right: 1px solid var(--color-17);
}
.btn {
  width: 37%;
}
button {
  width: 100%;
  border-radius: var(--borderRadius-1);
  font-size: 0.938em;
  cursor: pointer;
  background-color: var(--color-03);
  color: var(--color-01);
  font-family: var(--font-family-2);
  font-weight: 600;
  border: none;
  padding: 0.8rem 0;
}
p {
  font-size: 0.625em;
  color: var(--color-17);
  font-weight: 600;
}
.header-login {
  width: 100%;
  display: flex;
  justify-content: center;
}
.imgHeader {
  width: 37%;
}
.input-email {
  margin-bottom: 1.5rem;
}

.parrYear {
  padding-top: 4rem;
}
.error {
  margin-top: 1.5rem;
  color: red;
}
.errorInfo {
  color: red;
  margin-bottom: 1rem;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .inputs {
    width: 55%;
  }
  .btn {
    width: 55%;
  }
  .imgHeader {
    width: 50%;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .inputs {
    width: 45%;
  }
  .btn {
    width: 45%;
  }
}
@media (min-width: 2000px) {
  ::placeholder {
    line-height: 28px;
    color: var(--color-17);
  }
  .imgHeader {
    width: 33%;
  }
  .input-email,
  .input-pass {
    padding: 1.25rem 0;
    font-size: 1.25em;
    padding-left: 2rem;
  }
  button {
    font-size: 1.25em;
  }
  p {
    font-size: 1em;
  }
}
</style>

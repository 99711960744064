<template>
  <div class="container-tableUrl backPanelBlack">
    <div class="header-tableUrl">
      <p class="titleTableUrl textWhite">Historial url global</p>
    </div>
    <div class="container-reload">
      <span
        v-tooltip="{
          content: 'Actualizar',
          class: 'tooltip-custom ',
        }"
        class="reload"
        @click="reload"
      ></span>
    </div>
    <div class="tableRepGlo">
      <datatable
        title="prueba"
        :columns="tableColumns1"
        :clickable="false"
        :rows="tableRows1"
        :sortable="false"
        locale="es"
        :paginate="false"
        class="tableReportGlo"
      >
        <th class="th" slot="thead-tr">Tipo url</th>
        <th class="th" slot="thead-tr">Acciones</th>
        <template slot="tbody-tr" slot-scope="props">
          <td class="td">
            <div class="containerStatus">
              <p v-if="props.row.not_custom === true">Aleatoria</p>
              <p v-if="props.row.not_custom === false">Custom</p>
            </div>
          </td>
          <td class="td">
            <div class="container-router">
              <RouterLink
                to="/reportes/url-global"
                class="eye-content"
                @click.native="smsView(props.row)"
              >
                <span class="eye"></span>
              </RouterLink>
              <div class="download-content" @click="download(props.row)">
                <span class="download"></span>
              </div>
            </div>
          </td>
        </template>
      </datatable>
      <div class="selector">
        <span class="textPag textWhite">Página</span>
        <input class="numberPag" v-model="value" readonly />
        <span
          id="change_pag_l"
          class="icon-left"
          @click="pagination_manualL()"
        ></span>
        <span
          id="change_pag_r"
          class="icon-right"
          @click="pagination_manualR()"
        ></span>
      </div>
    </div>
    <Modal></Modal>
    <ModalNoData></ModalNoData>
  </div>
</template>
<script>
import DataTable from "vue-materialize-datatable";
import axios from "axios";
import Modal from "../Common/Modal";
import ModalNoData from "../Common/ModalNoData";
export default {
  components: {
    datatable: DataTable,
    Modal,
    ModalNoData,
  },
  computed: {},
  data() {
    return {
      dataInfo: "",
      dataPagOne: this.$store.state.urlList,
      rows: [],
      numberPag: this.$store.state.totalPageUrlList,
      value: this.$store.state.valuePaginationG,
      tableColumns1: [
        {
          label: "Fecha",
          field: "created_at",
        },
        {
          label: "Enlace corto",
          field: "short_url",
        },
        {
          label: "Enlace original",
          field: "url",
        },
        {
          label: "Clics",
          field: "countClick",
        },
      ],
      tableRows1: [],
    };
  },
  methods: {
    smsView(data) {
      this.$store.commit("saveOneCampaign", data);
    },
    filterData() {
      let data = this.$store.state.urlList;
      if (data.length != 0) {
        this.tableRows1 = data;
        /* console.log('filterdata')  */
        /* console.log(this.tableRows1) */
      } else {
        this.openModalNoData();
      }
    },
    filterDataPag() {
      let data = this.dataInfo;
      this.tableRows1 = data;
      /*  console.log('filterdatapag') */
      /*console.log(this.tableRows1) */
    },
    download(data) {
      this.$store.commit("saveOneCampaign", data);
      this.$store.dispatch("getDataOneUrlList");
      setTimeout(() => {
        if (this.$store.state.oneCampaign.countClick == 0) {
          this.openModal();
        } else {
          let base_code = this.$store.state.detailUrlList.base_code;
          let domain = this.$store.state.detailUrlList.domain;
          let id = this.$store.state.oneCampaign._id;
          axios({
            url: `${process.env.VUE_APP_SERVICE_URL}services/url/download/${id}`,
            method: "GET",
            responseType: "blob",
            headers: {
              Authorization: `bearer ${this.$store.state.token} `,
              "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
            },
          })
            .then((response) => {
              let fileURL = window.URL.createObjectURL(
                new Blob([response.data])
              );
              let fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", `${domain}-${base_code}.csv`);
              document.body.appendChild(fileLink);
              fileLink.click();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }, 250);
    },
    openModal() {
      this.$modal.show("modalNoData");
    },
    openModalNoData() {
      this.$modal.show("modalNoDataTa");
    },
    pagination_manualL() {
      if (this.value == 1) {
        return false;
      } else {
        this.value = this.value - 1;
        this.$store.commit("saveValuePaginationG", this.value);
        setTimeout(() => {
          this.getDataUrlListPag();
        }, 100);
      }
    },
    pagination_manualR() {
      if (this.numberPag <= this.value) {
        this.openModalNoData();
      } else {
        this.value = this.value + 1;
        this.$store.commit("saveValuePaginationG", this.value);
        setTimeout(() => {
          this.getDataUrlListPag();
        }, 100);
      }
    },
    getDataUrlListPag() {
      let headers = {
        Authorization: `bearer ${this.$store.state.token} `,
        "x-service-token": process.env.VUE_APP_SERVICE_TOKEN,
      };
      let data = {
        limit: 10,
        page: this.$store.state.valuePaginationG,
      };
      axios
        .post(`${process.env.VUE_APP_SERVICE_URL}services/url/list `, data, {
          headers: headers,
        })
        .then((res) => {
            /* console.log("getDataUrlListPag")   */
          this.dataInfo = res.data.data.info;
          this.filterDataPag();
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          this.error = true;
        });
    },
    reload() {
      
        /* alert('primero') */
        this.getDataUrlListPag();
      
    },
  },
  mounted() {
   /*  if (this.$store.state.valuePaginationG != 1) {
      this.getDataUrlListPag();
    } else {
      setTimeout(() => {
        this.filterData();
      }, 250);
    } */
    this.getDataUrlListPag();
  },
};
</script>
<style scoped>
.container-tableUrl {
  background: var(--color-01);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: var(--borderRadius-1);
  /*  margin-top: 2rem; */
  padding: 2rem 1rem;
  overflow-x: overlay;
  margin-bottom: 2rem;
}
.header-tableUrl {
  display: flex;
  margin-left: 2rem;
  flex-direction: column;
  align-items: flex-start;
}
.titleTableUrl {
  font-weight: 600;
  font-size: 1.563em;
  letter-spacing: 3px;
  color: var(--color-17);
}
.titleTableUrl {
  font-family: var(--font-family-2);
  color: var(--color-17);
}
.parr {
  font-family: var(--font-family-2);
  color: var(--text-01);
  letter-spacing: 3px;
  font-size: 0.938em;
  font-weight: 600;
  padding: 1rem 0 0.5rem 1rem;
  /*   line-height: 32px; */
  text-align: start;
}
.tableRepGlo {
  margin-top: 2rem;
}
.eye {
  content: url(../../assets/iconWhite/eye.svg);
}
.eye-content {
  background: var(--color-05);
  border-radius: var(--borderRadius-1);
  display: flex;
  width: 2.5rem;
  justify-content: space-evenly;
  align-items: center;
  height: 3vh;
}
.container-router {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.download {
  content: url(../../assets/iconLila/download.svg);
}
.download-content {
  background: var(--color-18);
  border-radius: var(--borderRadius-1);
  display: flex;
  width: 2.5rem;
  justify-content: space-evenly;
  align-items: center;
  height: 3vh;
  cursor: pointer;
}
.info {
  font-family: var(--font-family-2);
  font-style: normal;
  font-weight: 600;
  font-size: 0.938em;
  line-height: 19px;
  text-align: center;
  color: var(--text-02);
}
.selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.numberPag {
  outline: none;
  width: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 10px;
  margin-left: 0.5rem;
  text-align: center;
  font-size: 12px;
}
.textPag {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.material-icons {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.icon-left {
  content: url(../../assets/iconGrey/left.svg);
  cursor: pointer;
}
.icon-right {
  content: url(../../assets/iconGrey/right.svg);
  cursor: pointer;
}
.reload {
  content: url(../../assets/iconOrange/reload.svg);
  width: 40px;
  height: 40px;
  transform: scale(0.9);
  cursor: pointer;
}
.container-reload {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .container-tableUrl {
    width: 100%;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .container-tableUrl::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 10px;
    border: 2px solid #f1f2f3;
  }
  .container-tableUrl::-webkit-scrollbar:horizontal {
    height: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .titleTableUrl {
    font-size: 1.25em;
  }
  .container-tableUrl {
    width: 90%;
  }
  .titleTableUrl {
    font-size: 1.25em;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  .container-tableUrl::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 10px;
    border: 2px solid #f1f2f3;
  }
  .container-tableUrl::-webkit-scrollbar:horizontal {
    height: 10px;
  }
}
</style>
